import React from 'react';
import DTHBoolFilterLayout from './DTHBoolFilterLayout';
import DTHBaseFilterLayout from './DTHBaseFilterLayout';
import DTHDateRangeFilterLayout from './DTHDateRangeFilterLayout';
import DTHExtDateRangeFilterLayout from './DTHExtDateRangeFilterLayout';
import DTHNumberFilterLayout from './DTHNumberFilterLayout';
import DTHSearchFilterLayout from './DTHSearchFilterLayout';
import DTHDefaultFilterLayout from './DTHDefaultFilterLayout';
import DTHDateFilterLayout from './DTHDateFilterLayout';

const Components = {
  'default-default': DTHDefaultFilterLayout,
  'date-date': DTHDateFilterLayout,
  'date-date_range': DTHDateRangeFilterLayout,
  'date-date_range_extended': DTHExtDateRangeFilterLayout,
  'number-number': DTHNumberFilterLayout,
  'enum-bool': DTHBoolFilterLayout,
  'enum-static': DTHBoolFilterLayout,
  'enum-dynamic': DTHBoolFilterLayout,
  'string-base': DTHBaseFilterLayout,
  'string-search': DTHSearchFilterLayout,
};

export default block => {
  // component does exist
  if (typeof Components[block.component] !== 'undefined') {
    return React.createElement(Components[block.component], {
      key: block.key,
      data: block.data,
      filterData: block.filterData,
      onFilterChange: block.onFilterChange,
    });
  }
  // component doesn't exist yet
  return React.createElement(Components['default-default'], {
    key: block.key,
    data: block.data,
    filterData: block.filterData,
    onFilterChange: block.onFilterChange,
  });
}
