export const CELL_WRAP_TYPE_CONSTANTS = Object.freeze({
  WRAP_DYNAMIC: 'wrap-dynamic',
  WRAP_SCROLL_X: 'wrap-scroll-x',
  WRAP_SCROLL_Y: 'wrap-scroll-y',
  CLIP: 'clip',
  DYNAMIC: 'dynamic',
});

export const FILTER_TYPE = Object.freeze({
  STRING: 'string',
  NUMBER: 'number',
  ENUM: 'enum',
  BOOL: 'bool',
  DATE: 'date',
  CUSTOM: 'custom',
});

export const ENUM_FILTER_SUB_TYPES = Object.freeze({
  BOOL: 'bool',
  STATIC: 'static',
  DYNAMIC: 'dynamic',
});

export const DATE_FILTER_SUB_TYPE = Object.freeze({
  DATE_RANGE: 'date_range',
  DATE_RANGE_EXTENDED: 'date_range_extended',
});

export const STRING_FILTER_SUB_TYPE = Object.freeze({
  BASE: 'base',
  SEARCH: 'search',
});
